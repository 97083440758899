
// 針對tech_tnga.aspx 帶入舊元件使用之相關物件
import TheModel from '@/components/module/TheModel';

const { $ } = window;

window.gapv = (page, title, cb) => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: 'GAPV',
  };
  if (typeof (cb) === 'function') data.eventCallback = cb;
  if (title) data.VirtualPageTitle = title;
  if (page) {
    data.VirtualPageURL = page;
    window.dataLayer.push(data);
  }
};

window.gaev = (category, action, label, value, cb) => {
  window.dataLayer = window.dataLayer || [];
  const data = {
    event: 'GAEV',
    EventCategory: category,
    EventAction: action,
    EventLabel: label,
    EventValue: value,
  };
  if (typeof (cb) === 'function') data.eventCallback = cb;
  window.dataLayer.push(data);
};

$(window).ready(() => {
  window.TheModel = new TheModel();
});
