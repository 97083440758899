import { EventEmitter } from 'events';
import '@/vendors/milkmidi.device';

EventEmitter.defaultMaxListeners = 15;

const { $ } = window;

let isWindowLoaded = false;

export default class TheModel extends EventEmitter {
  constructor() {
    super();
    this.$window = $(window);
    this.device = window.device;
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();
    this.bodyScrollTop = this.$window.scrollTop();

    this.$window.on('load', () => {
      console.log('$window.load');
      this.windowLoaded();
    }).on('resize', () => {
      this.windowWidth = this.$window.width();
      this.windowHeight = this.$window.height();
      this.bodyScrollTop = this.$window.scrollTop() || $('body').scrollTop();
      this.emit('resize', this.windowWidth, this.windowHeight);
    }).on('scroll', () => {
      this.bodyScrollTop = this.$window.scrollTop() || $('body').scrollTop();
      this.emit('scroll', this.bodyScrollTop);
    });
  }

  windowLoaded() {
    if (isWindowLoaded) return;
    isWindowLoaded = true;

    console.log('TheModel.windowLoaded');
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();
    this.bodyScrollTop = this.$window.scrollTop() || $('body').scrollTop();
    this.emit('loaded');
  }

  scrollTop(ypos = 0, time = 600) {
    let $body;
    if (window.opera) {
      $body = document.compatMode === 'CSS1Compat' ? $('html') : $('body');
    } else {
      $body = $('html,body');
    }

    $body.animate({ scrollTop: ypos }, time, 'swing');
    this.emit('scrollTop');
  }

  prependListener(type, listener) {
    // eslint-disable-next-line no-underscore-dangle
    this._events[type] = [listener].concat(this.listeners(type));
  }

  prependOnceListener(type, listener) {
    let fired = false;
    function xyz() {
      this.removeListener(type, xyz);
      if (!fired) {
        fired = true;
        // eslint-disable-next-line prefer-rest-params
        listener.apply(this, arguments);
      }
    }
    xyz.listener = listener;
    this.prependListener(type, xyz);
  }
}
