import AOS from 'aos';

export default {
  init(aosSettings) {
    if (window.AOSApi === undefined) {
      window.AOSApi = AOS;
    }

    window.AOSSettings = aosSettings;

    window.AOSApi.init(window.AOSSettings);
  },

  refresh() {
    if (window.AOSApi === undefined) {
      this.init();
    }

    window.AOSApi.refresh();
  },
  refreshHard() {
    if (window.AOSApi === undefined) {
      this.init();
    }

    window.AOSApi.refreshHard();
  },
};
