import Vue from 'vue';
import $ from 'jquery';
import gsap, { TweenMax, TimelineMax } from 'gsap';
import { hasTouch } from '@/utils/device';
import {
  helpBtn,
} from './global-help-extent';

// function triggerHelp() {
//   $('.icon-help').parent().click((event) => {
//     event.preventDefault();
//     $('#help-trigger')[0].click();
//   });
// }

export default () => {
  const helpVm = new Vue({
    el: '#global-help',
    data() {
      return {
        /* Device informations */
        hasTouch: false,

        viewport: {
          height: 0,
          width: 0,
        },

        size: {
          headerHeight: 0,
          mobile: {
            search: 0,
            toggler: 0,
            contentMaxHeight: 0,
          },
        },

        disableBodyScrollMenu: ['help'],
        disableBackgroundMenu: ['member'],
        disableBackdropMenu: ['member'],

        /* Large Menu */
        preOpenMenu: null,
        currentMenu: null,

        /* Mobile Menu */
        currentGroup: null,

        largeMenuMaxHeight: 0,

        scrollContent: [],
      };
    },

    computed: {
      isMenuShow() {
        return this.currentMenu !== null;
      },

      isHelpOpen() {
        return this.currentMenu === 'help';
      },

      isMobileMenuOpen() {
        return this.currentMenu === 'mobile' || (this.isMobile === true && this.currentMenu === 'showcase');
      },

      isBackgroundShow() {
        return this.isMenuShow && !this.disableBackgroundMenu.includes(this.currentMenu);
      },

      isBackdropShow() {
        return this.isMenuShow && !this.disableBackdropMenu.includes(this.currentMenu);
      },

      menuSectionStyle() {
        return {
          maxHeight: this.largeMenuMaxHeight > 0 ? `${this.largeMenuMaxHeight}px` : null,
        };
      },

      isMobile() {
        return this.viewport.width < 1024;
      },
    },

    methods: {
      openMenu(targetId) {
        this.preOpenMenu = targetId;
        this.currentMenu = targetId;
      },
      toggleMenu(targetId) {
        if (targetId === this.currentMenu) {
          this.closeMenu(targetId);
        } else {
          this.closeMenu(this.currentMenu);

          this.$nextTick(() => {
            this.openMenu(targetId);
          });
        }
      },
      closeMenu(targetId) {
        if (targetId === 'mobile') {
          this.resetMobileGroup();
        }

        this.preOpenMenu = null;
        this.currentMenu = null;
      },
      checkDevice() {
        this.hastouch = hasTouch();
      },

      /* Menu transitions */
      menuBackgroundLeave(el, done) {
        TweenMax.to(el, {
          height: 0,
          duration: 0.3,
          onComplete() {
            done();
          },
        });
      },

      /* Mobile menu transitions */
      mobileMenuBeforeEnter(el) {
        gsap.set(el, {
          autoAlpha: 0,
          x: -20,
        });
      },
      mobileMenuEnter(el, done) {
        const self = this;

        new TimelineMax({
          onComplete() {
            self.calculateMobileMenuHeight(el);
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 1,
            x: 0,
            duration: 0.4,
          }));
      },
      mobileMenuLeave(el, done) {
        new TimelineMax({
          onComplete() {
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 0,
            x: -20,
            duration: 0.4,
          }));
      },

      /* help menu transitions */
      HelpCenterBeforeEnter(el) {
        gsap.set(el, {
          autoAlpha: 0,
          x: 50,
        });
      },
      HelpCenterEnter(el, done) {
        // const self = this;

        new TimelineMax({
          onComplete() {
            // self.calculateMobileMenuHeight(el);
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 1,
            x: 0,
            duration: 0.4,
          }));
      },
      HelpCenterLeave(el, done) {
        new TimelineMax({
          onComplete() {
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 0,
            x: 50,
            duration: 0.4,
          }));
      },

      /* Event handlers */
      clickNavItemHandler(targetId, trigger) {
        if (trigger === 'menu-icon' && (this.isMobile === true && this.currentMenu === 'showcase')) {
          this.closeMenu();
          return;
        }
        this.toggleMenu(targetId);
      },

      mouseEnterHandler(targetId) {
        if (this.hasTouch === true) {
          return;
        }

        this.openMenu(targetId);
      },

      mouseLeaveHandler(targetId) {
        if (this.hasTouch === true) {
          return;
        }

        this.delayToCloseMenu(targetId);
      },

      windowResizeHandler() {
        this.checkDevice();
      },
    },

    mounted() {
      // triggerHelp();
      this.windowResizeHandler();
      helpBtn();
      window.addEventListener('resize', this.windowResizeHandler);
    },

    beforeDestroy() {
      this.scrollContent.forEach((sct) => {
        sct.destroy();
      });
      window.removeEventListener('resize', this.windowResizeHandler);
    },
  });
  return helpVm;
};
