import { isMobile } from '@/utils/device';

const { $ } = window;

function initialSelect() {
  if (!isMobile()) {
    $('.js-select').select2({
      minimumResultsForSearch: -1,
      width: 'style',
    });
  }
}

function destroySelect() {
  if ($('.js-select').hasClass('select2-hidden-accessible')) {
    $('.js-select').select2('destroy');
  }
}

function reInitialSelect() {
  destroySelect();
  initialSelect();
}

$(window).ready(() => {
  initialSelect();

  $(window).on('resize', () => {
    reInitialSelect();
  });
});
