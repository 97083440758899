import Vue from 'vue';
import Swiper from 'swiper/js/swiper';
import gsap, { TweenMax, TimelineMax } from 'gsap';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import { hasTouch } from '@/utils/device';
import {
  initQueryFilter, collectBtn, trackingBtn, querFilter, initBenefitsAndPrivacy,
} from './global-menu-extent';

const { $ } = window;
// eslint-disable-next-line no-unused-vars
let swiperCollect = null;
// const swiperHistory = null;
// eslint-disable-next-line no-unused-vars
let swiperProductSelect = null;

const priceSliderValue = [50.0, 350.0];
const exhaustSliderValue = [1000, 3000];

function initSwiperCollect() {
  swiperCollect = new Swiper('.swiper-collect .swiper-container', {
    slidesPerView: 1,
    centeredSlides: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: '.swiper-collect .swiper-button-next',
      prevEl: '.swiper-collect .swiper-button-prev',
    },
    breakpoints: {
      768: {
        centeredSlides: false,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 10,
      },
      1024: {
        centeredSlides: false,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10,
      },
    },
  });
}

function initSwiperProductSelect() {
  swiperProductSelect = new Swiper('#product-showcase .swiper-container', {
    slidesPerView: 'auto',
    slidesOffsetBefore: 10,
    slideToClickedSlide: true,
    normalizeSlideIndex: false,
    observer: true,
    observeParents: true,
    freeMode: true,
    freeModeSticky: true,
    freeModeMomentumRatio: 0.5,
    freeModeMomentumVelocityRatio: 0.5,
    breakpoints: {
      768: {
        slidesOffsetBefore: 0,
      },
    },
  });
}

function showcasePopup() {
  const menuClass = '.global-menu-desktop';
  $(`${menuClass} .scroll-menu [data-btn]`).on('click', (e) => {
    const btn = $(e.target).attr('data-btn');
    $(e.target).toggleClass('active');
    $(`${menuClass} .pop-up[data-pop]`).not(`.pop-up[data-pop=${btn}]`).hide();
    $(`${menuClass} .scroll-menu [data-btn]`).not(`.scroll-menu [data-btn=${btn}]`).removeClass('active');
    $(`${menuClass} .pop-up[data-pop=${btn}]`).fadeToggle(300);
  });
  // 儲存
  $(`${menuClass} .pop-up a.save`).click(function save() {
    const popup = $(this).closest('.pop-up').attr('data-pop');
    if (popup === 'price') {
      const sliderValuelower = $(`${menuClass} [data-pop=${popup}]`).find('.noUi-handle-lower').attr('aria-valuenow');
      const sliderValueupper = $(`${menuClass} [data-pop=${popup}]`).find('.noUi-handle-upper').attr('aria-valuenow');
      // 判斷 price slider 是否初始值
      if (parseFloat(sliderValuelower) === priceSliderValue[0] && parseFloat(sliderValueupper) === priceSliderValue[1]) {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).removeClass('checked');
        $(`${menuClass} .pop-up[data-pop="price"] a.remove`).addClass('disabled');
      } else {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).addClass('checked');
      }
    }
    if (popup === 'exhaust') {
      const sliderValuelower = $(`${menuClass} [data-pop=${popup}]`).find('.noUi-handle-lower').attr('aria-valuenow');
      const sliderValueupper = $(`${menuClass} [data-pop=${popup}]`).find('.noUi-handle-upper').attr('aria-valuenow');
      // 判斷 exhaust slider 是否初始值
      if (parseFloat(sliderValuelower) === exhaustSliderValue[0] && parseFloat(sliderValueupper) === exhaustSliderValue[1]) {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).removeClass('checked');
      } else {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).addClass('checked');
      }
    }
    if (popup === 'engine' || popup === 'carType' || popup === 'band' || popup === 'memberCount' || popup === 'sot') {
      const checked = $(`${menuClass} [data-pop=${popup}] input:checked`).length;
      // 判斷 engine carType band checkbox 是否選取
      if (checked !== 0) {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).addClass('checked');
      } else {
        $(`${menuClass} .scroll-menu [data-btn=${popup}]`).removeClass('checked');
      }
    }
    $(this).parents('.pop-up').fadeOut(300);
    $(`${menuClass} .scroll-menu [data-btn=${popup}]`).removeClass('active');
    // 判斷儲存篩選狀態
    const checkedBox = $('.scroll-menu a.checked').length;
    if (checkedBox > 0) {
      $(`${menuClass} .remove-all`).show();
    } else {
      $(`${menuClass} .remove-all`).hide();
    }
  });
  // 清除
  $(`${menuClass} .pop-up input`).change(function checkL() {
    const checkLegth = $(this).closest('.pop-up').find('input:checked').length;
    console.log(checkLegth);
    if (checkLegth > 0) {
      $(this).closest('.pop-up').find('.remove').removeClass('disabled');
    } else {
      $(this).closest('.pop-up').find('.remove').addClass('disabled');
    }
  });
  $(`${menuClass} .pop-up a.remove`).click(function remove() {
    const popup = $(this).closest('.pop-up').attr('data-pop');
    $(this).addClass('disabled');
    // 建議售價
    if (popup === 'price') {
      const slider = document.getElementById('price-slider');
      slider.noUiSlider.set(priceSliderValue);
      setTimeout(() => {
        $(this).addClass('disabled');
      }, 100);
    }
    if (popup === 'exhaust') {
      const slider = document.getElementById('exhaust-slider');
      slider.noUiSlider.set(exhaustSliderValue);
      setTimeout(() => {
        $(this).addClass('disabled');
      }, 100);
    }
    // 引擎動力 車型 品牌
    if (popup === 'engine' || popup === 'carType' || popup === 'band' || popup === 'memberCount' || popup === 'sot') {
      $(`${menuClass} .pop-up[data-pop=${popup}]`)
        .find('input')
        .prop('checked', false);
    }
  });
  // 清除篩選 all
  $(`${menuClass} .remove-all`).click(() => {
    document.getElementById('price-slider').noUiSlider.set(priceSliderValue);
    document.getElementById('exhaust-slider').noUiSlider.set(exhaustSliderValue);
    $(`${menuClass} .remove-all`).hide();
    $(`${menuClass} .scroll-menu [data-btn]`).removeClass('checked');
    $(`${menuClass} .pop-up[data-pop=engine],${menuClass} .pop-up[data-pop=carType],${menuClass} .pop-up[data-pop=band],${menuClass} .pop-up[data-pop=memberCount],${menuClass} .pop-up[data-pop=sot]`)
      .find('input[type="checkbox"]')
      .prop('checked', false);
    // 還原顯示全部&排序
    querFilter(null, []);
    querFilter('sot', []);
    setTimeout(() => {
      $(`${menuClass} .pop-up a.remove`).addClass('disabled');
    }, 100);
  });

  // 隱藏
  $(document).on('click', (e) => {
    const dataBtn = $(`${menuClass} [data-btn]`);
    const con = $(`${menuClass} .pop-up`);
    if (!con.is(e.target) && !dataBtn.is(e.target) && con.has(e.target).length === 0 && dataBtn.has(e.target).length === 0) {
      $(`${menuClass} .pop-up`).fadeOut();
      $(`${menuClass} [data-btn].active`).removeClass('active');
    }
  });
}

function priceRangeSlider() {
  const priceslider = document.getElementById('price-slider');
  const minPriceInput = document.getElementById('menuMinPrice');
  const maxPriceInput = document.getElementById('menuMaxPrice');
  noUiSlider.create(priceslider, {
    start: priceSliderValue,
    step: 1,
    margin: 1,
    tooltips: [true, true],
    connect: true,
    range: {
      min: priceSliderValue[0],
      max: priceSliderValue[1],
    },
    format: wNumb({
      decimals: 0,
      // suffix: '萬',
    }),
  });
  const exhaustslider = document.getElementById('exhaust-slider');
  noUiSlider.create(exhaustslider, {
    start: exhaustSliderValue,
    step: 100,
    tooltips: [true, true],
    connect: true,
    range: {
      min: exhaustSliderValue[0],
      max: exhaustSliderValue[1],
    },
    format: wNumb({
      decimals: 0,
      suffix: 'cc',
    }),
  });

  priceslider.noUiSlider.on('update', (values, handle) => {
    if (handle === 0) {
      minPriceInput.value = values[handle];
    } else if (handle === 1) {
      maxPriceInput.value = values[handle];
    }
  });

  priceslider.noUiSlider.on('set', () => {
    $('[data-pop="price"] .remove').removeClass('disabled');
  });

  minPriceInput.addEventListener('change', (event) => {
    priceslider.noUiSlider.set([event.target.value, null]);
  });

  maxPriceInput.addEventListener('change', (event) => {
    priceslider.noUiSlider.set([null, event.target.value]);
  });
}

function myListTips(callback) {
  $('#myListTipsClose').on('click', () => {
    $('#myListTips').hide();
    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
  });
}

function getMenuSubjectListType(num) {
  let type = '';
  switch (num) {
    case 6:
      type = 'menu-subject-list-lg-6';
      break;
    case 7:
      type = 'menu-subject-list-lg-7';
      break;
    case 8:
      type = 'menu-subject-list-lg-4';
      break;
    case 9:
      type = 'menu-subject-list-lg-5';
      break;
    case 10:
      type = 'menu-subject-list-lg-5';
      break;
    default:
      break;
  }
  return type;
}

function getMenuSubjectList(unit) {
  const { length } = $(unit).find('li');
  const type = getMenuSubjectListType(length);
  $(unit).find('.menu-subject-list').addClass(type);
}


export default () => {
  const headerVm = new Vue({
    el: '#global-header',
    data() {
      return {
        /* Device informations */
        hasTouch: false,

        viewport: {
          height: 0,
          width: 0,
        },

        size: {
          headerHeight: 0,
          mobile: {
            search: 0,
            toggler: 0,
            contentMaxHeight: 0,
          },
        },

        disableBodyScrollMenu: ['showcase', 'purchase', 'branding', 'after-sale', 'tracking', 'mobile'],
        disableBackgroundMenu: ['member'],
        disableBackdropMenu: ['member'],

        /* Large Menu */
        preOpenMenu: null,
        currentMenu: null,

        /* Mobile Menu */
        currentGroup: null,

        largeMenuMaxHeight: 0,

        scrollContent: [],
      };
    },

    computed: {
      isMenuShow() {
        return this.currentMenu !== null;
      },
      isShowcaseOpen() {
        return this.currentMenu === 'showcase';
      },
      isPurchaseOpen() {
        return this.currentMenu === 'purchase';
      },
      isBrandingOpen() {
        return this.currentMenu === 'branding';
      },
      isAfterSaleOpen() {
        return this.currentMenu === 'after-sale';
      },
      isSearchOpen() {
        return this.currentMenu === 'search';
      },
      isTrackingOpen() {
        return this.currentMenu === 'tracking';
      },
      isMemberOpen() {
        return this.currentMenu === 'member';
      },
      isMobileMenuOpen() {
        return this.currentMenu === 'mobile' || (this.isMobile === true && this.currentMenu === 'showcase');
      },

      isMobilePurchaseOpen() {
        return this.currentGroup === 'mobile-purchase';
      },

      isMobileBrandingOpen() {
        return this.currentGroup === 'mobile-branding';
      },

      isMobileAfterSaleOpen() {
        return this.currentGroup === 'mobile-after-sale';
      },

      isBackgroundShow() {
        return this.isMenuShow && !this.disableBackgroundMenu.includes(this.currentMenu);
      },

      isBackdropShow() {
        return this.isMenuShow && !this.disableBackdropMenu.includes(this.currentMenu);
      },

      menuSectionStyle() {
        return {
          maxHeight: this.largeMenuMaxHeight > 0 ? `${this.largeMenuMaxHeight}px` : null,
        };
      },

      isMobile() {
        return this.viewport.width < 1024;
      },
    },

    watch: {
      currentMenu(newVal) {
        if (this.isMenuShow === true && this.disableBodyScrollMenu.includes(newVal)) {
          $('body').addClass('is-menu-opened');
        } else {
          $('body').removeClass('is-menu-opened');
        }
      },
    },

    methods: {
      /* Desktop group events */
      openMenu(targetId) {
        this.preOpenMenu = targetId;
        setTimeout(() => {
          if (this.preOpenMenu === targetId) {
            this.currentMenu = targetId;
          }
        }, 250);
      },
      closeMenu(targetId) {
        if (targetId === 'mobile') {
          this.resetMobileGroup();
        }

        this.preOpenMenu = null;
        this.currentMenu = null;
      },
      toggleMenu(targetId) {
        if (targetId === this.currentMenu) {
          this.closeMenu(targetId);
        } else {
          this.closeMenu(this.currentMenu);

          this.$nextTick(() => {
            this.openMenu(targetId);
          });
        }
      },
      delayToCloseMenu() {
        this.preOpenMenu = null;
        setTimeout(() => {
          if (this.preOpenMenu === null) {
            this.currentMenu = null;
          }
        }, 250);
      },

      /* Mobile group events */
      toggleMobileGroup(id) {
        if (id === this.currentGroup) {
          // if (this[id] === true) {
          //   this.closeMobileGroup(id);
          // } else {
          //   this.openMobileGroup(id);
          // }
          this.closeMobileGroup(id);
        } else {
          this.closeMobileGroup(this.currentGroup);
          this.openMobileGroup(id);
        }
      },
      closeMobileGroup() {
        // this[targetId] = false;
        this.currentGroup = null;
      },
      openMobileGroup(targetId) {
        if (this.currentGroup === targetId) {
          return;
        }
        if (this.currentGroup !== null) {
          this.closeMobileGroup(this.currentGroup);
        }
        // this[targetId] = true;
        this.currentGroup = targetId;
      },
      resetMobileGroup() {
        if (this.currentGroup !== null) {
          this.closeMobileGroup(this.currentGroup);
        }
      },

      checkDevice() {
        this.hasTouch = hasTouch();
      },

      updateViewport() {
        this.viewport.height = window.innerHeight;
        this.viewport.width = window.innerWidth;

        this.size.headerHeight = this.$el.getBoundingClientRect().height;
      },

      updateLargeMenuMaxHeight() {
        this.largeMenuMaxHeight = Math.ceil((window.innerHeight - this.$refs.globalNavigation.getBoundingClientRect().height) - (this.isMobile ? 0 : 10));
      },

      getMenuWrapMaxHeight(el) {
        const elHeight = el.getBoundingClientRect().height;
        console.log(elHeight);

        return (elHeight > this.largeMenuMaxHeight) ? this.largeMenuMaxHeight : elHeight;
      },

      calculateMobileMenuHeight(el) {
        const menuWrap = el;
        const menuSearch = menuWrap.querySelector('#mobile-menu-search');
        const navItems = menuWrap.querySelectorAll('.mobile-navbar-item');

        const menuWrapHeight = this.viewport.height - this.size.headerHeight;
        const menuSearchHeight = menuSearch.getBoundingClientRect().height;
        const navItemHeight = [...navItems].map((navEl) => navEl.getBoundingClientRect().height);

        const subListWrapMaxHeight = menuWrapHeight - menuSearchHeight - (Math.max(...navItemHeight) * navItems.length) - (12 * 2);

        this.size.mobile.contentMaxHeight = subListWrapMaxHeight;
      },

      updateMenuBackground(el) {
        const self = this;
        return new TimelineMax()
          .add(TweenMax.to(self.$refs.globalMenuBackground, {
            height: self.getMenuWrapMaxHeight(el),
            duration: 0.3,
          }));
      },

      /* Transition callback */
      menuBeforeEnter(el) {
        gsap.set(el, {
          opacity: 0,
          display: 'flex',
        });
      },
      menuEnter(el, done) {
        const self = this;

        new TimelineMax({
          onComplete() {
            // const menuBody = self.scrollContent.find((sel) => sel.element === el.querySelector('.menu-section-body'));
            // menuBody.update();
            done();
          },
        })
          .add(self.updateMenuBackground(el))
          .add(TweenMax.to(el, {
            opacity: 1,
            duration: 0.3,
          }), '-=0.15');
      },
      menuLeave(el, done) {
        TweenMax.to(el, {
          opacity: 0,
          duration: 0.3,
          onComplete() {
            done();
          },
        });
      },

      /* Menu transitions */
      menuBackgroundLeave(el, done) {
        TweenMax.to(el, {
          height: 0,
          duration: 0.3,
          onComplete() {
            done();
          },
        });
      },

      /* Mobile menu transitions */
      mobileMenuBeforeEnter(el) {
        gsap.set(el, {
          autoAlpha: 0,
          x: -20,
        });
      },
      mobileMenuEnter(el, done) {
        const self = this;

        new TimelineMax({
          onComplete() {
            self.calculateMobileMenuHeight(el);
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 1,
            x: 0,
            duration: 0.4,
          }));
      },
      mobileMenuLeave(el, done) {
        new TimelineMax({
          onComplete() {
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 0,
            x: -20,
            duration: 0.4,
          }));
      },

      /* Mobile group transitions */
      mobileGroupBeforeEnter(el) {
        gsap.set(el, {
          autoAlpha: 0,
        });
      },
      mobileGroupEnter(el, done) {
        const elHeight = el.clientHeight;
        new TimelineMax({
          onComplete() {
            done();
          },
        })
          .add(TweenMax.fromTo(el, {
            height: 0,
          }, {
            height: Math.min(elHeight, this.size.mobile.contentMaxHeight),
            duration: 0.4,
          }))
          .add(TweenMax.to(el, {
            autoAlpha: 1,
            duration: 0.4,
            // clearProps: 'all',
          }), '-=0.4');
      },
      mobileGroupLeave(el, done) {
        new TimelineMax({
          onComplete() {
            done();
          },
        })
          .add(TweenMax.to(el, {
            autoAlpha: 0,
            height: 0,
            clearProps: 'all',
            duration: 0.4,
          }));
      },

      /* Event handlers */
      clickNavItemHandler(targetId, trigger) {
        if (trigger === 'menu-icon' && (this.isMobile === true && this.currentMenu === 'showcase')) {
          this.closeMenu();
          return;
        }
        this.toggleMenu(targetId);
      },
      clickNavItemMobileHandler(targetId) {
        if (hasTouch()) {
          this.toggleMenu(targetId);
        }
      },
      clickNavItemPreventHandler(event, targetId) {
        event.preventDefault();

        if (targetId !== undefined && hasTouch()) {
          this.toggleMenu(targetId);
        }
      },

      mouseEnterHandler(targetId) {
        if (this.hasTouch === true) {
          return;
        }

        this.openMenu(targetId);
      },

      mouseLeaveHandler(targetId) {
        if (this.hasTouch === true) {
          return;
        }

        this.delayToCloseMenu(targetId);
      },

      mobileMenuItemClickHandler(targetId) {
        this.toggleMobileGroup(targetId);
      },

      bootstrapMyListTabHandler() {
        $('#navbar-my-list-tab a[data-toggle="pill"]').on('shown.bs.tab', () => {
          this.updateMenuBackground(document.querySelector('#global-menu-trackgin'));
        });
      },

      windowResizeHandler() {
        this.checkDevice();
        this.updateViewport();
        this.updateLargeMenuMaxHeight();
      },
    },

    mounted() {
      showcasePopup();
      priceRangeSlider();
      initSwiperCollect();
      initSwiperProductSelect();
      initBenefitsAndPrivacy();
      initQueryFilter();
      collectBtn(swiperCollect);
      trackingBtn(this.toggleMenu);
      getMenuSubjectList('#global-menu-purchase');
      getMenuSubjectList('#global-menu-branding');
      getMenuSubjectList('#global-menu-after-sale');
      myListTips(() => {
        this.updateMenuBackground(document.querySelector('#global-menu-trackgin'));
      });

      this.bootstrapMyListTabHandler();
      this.windowResizeHandler();

      window.addEventListener('resize', this.windowResizeHandler);
    },

    beforeDestroy() {
      this.scrollContent.forEach((sct) => {
        sct.destroy();
      });
      window.removeEventListener('resize', this.windowResizeHandler);
    },
  });
  window.headerVm = headerVm;
  return headerVm;
};
