
import $ from 'jquery';

const showCaseStyle = '#global-menu-showcase';
const carlist = `${showCaseStyle} .car-show-group .row`;

function sortMethod(a, b, field, fieldLowerCase) {
  let fieldNew;
  let sortB; let sortA; let isHotB; let isHotA;
  switch (field) {
    case 'HOTS':
      // 量化 hots hotsdt
      fieldNew = 'hotsdt';
      sortB = parseFloat($(b).data(fieldLowerCase));
      sortA = parseFloat($(b).data(fieldLowerCase));
      isHotB = sortB > 0;
      isHotA = sortA > 0;
      if (isHotB) {
        sortB += parseFloat($(b).data(fieldNew));
      }
      if (isHotA) {
        sortA += parseFloat($(a).data(fieldNew));
      }
      if (sortB > sortA) {
        return 1;
      }
      if (sortB < sortA) {
        return -1;
      }
      return 0;
    case 'PRIA':
      if (parseFloat($(b).data(fieldLowerCase)) < parseFloat($(a).data(fieldLowerCase))) {
        return 1;
      }
      if (parseFloat($(b).data(fieldLowerCase)) > parseFloat($(a).data(fieldLowerCase))) {
        return -1;
      }
      return 0;
    case 'PRIB':
      if (parseFloat($(b).data(fieldLowerCase)) > parseFloat($(a).data(fieldLowerCase))) {
        return 1;
      }
      if (parseFloat($(b).data(fieldLowerCase)) < parseFloat($(a).data(fieldLowerCase))) {
        return -1;
      }
      return 0;
    default:
      return ($(b).data('sot')) < ($(a).data('sot')) ? 1 : -1;
  }
}

// 單選排序
function sortField(fieldvalue) {
  let field = '';
  const index1 = 0;
  const index2 = 1;
  if (fieldvalue.length === 1) {
    field = fieldvalue[index1];
    const fieldLowerCase = field.toLowerCase();
    $(`${carlist} .col-6`).sort((a, b) => sortMethod(a, b, field, fieldLowerCase)).appendTo(carlist);
  } else if (fieldvalue.length === 2) {
    field = fieldvalue[index1];
    const fieldLowerCase = field.toLowerCase();
    const field2 = fieldvalue[index2];
    const fieldLowerCase2 = field2.toLowerCase();
    $(`${carlist} .col-6`).sort((a, b) => sortMethod(a, b, field2, fieldLowerCase2)).sort((a, b) => sortMethod(a, b, field, fieldLowerCase)).appendTo(carlist);
  } else {
    $(`${carlist} .col-6`).sort((a, b) => sortMethod(a, b, '', [])).appendTo(carlist);
  }
}

// 過濾類型
function filterType(element, type, filtervalue) {
  const indexMin = 0;
  const indexMax = 1;
  switch (type) {
    case 'memberCount':
    case 'carType':
    case 'engine':
    case 'band': {
      const fieldLowerCase = type.toLowerCase();
      const fieldvalue = $(element).data(fieldLowerCase);
      const array = fieldvalue.toString().split(',');
      // 兩個陣列交集
      const result = array.filter((ele) => filtervalue.indexOf(ele) > -1);
      if (result.length === 0) {
        return false;
      }
      break;
    }
    case 'price': {
      const priA = parseFloat($(element).data('pria'));
      const priB = parseFloat($(element).data('prib'));
      const min = parseFloat(filtervalue[indexMin]);
      const max = parseFloat(filtervalue[indexMax]);
      if (priA >= min && priA <= max) {
        return true;
      }
      if (priB >= min && priB <= max) {
        return true;
      }
      return false;
    }
    case 'exhaust': {
      const exhausts = $(element).data('exhaust');
      const array = exhausts.toString().split(',');
      const min = parseFloat(filtervalue[indexMin]);
      const max = parseFloat(filtervalue[indexMax]);
      for (let i = 0; i < array.length; i += 1) {
        const exh = parseFloat(array[i]);
        if (exh >= min && exh <= max) {
          return true;
        }
      }
      return false;
    }
    default: {
      return true;
    }
  }
  return true;
}

// 顯示查詢結果
function filterResult() {
  const carCount = $(`${carlist} [data-cartype]:visible`).length;
  $(`${showCaseStyle} .d-xl-none`).text(`共篩選出 ${carCount} 個車款`);
}

// 過濾條件
export function querFilter(field, values) {
  const list = $(`${carlist} .col-6`);
  if (field === 'sot') {
    sortField(values);
    filterResult(list);
    return;
  }

  list.hide();
  const filterCount = $('.scroll-menu [data-btn].checked').length;

  // 判斷是否有選擇條件
  if (filterCount === 0) {
    list.show();
    return;
  }
  // 取得所有過濾條件
  const filterWheres = [];
  const filters = $(`${showCaseStyle} .select-block .pop-up`);
  filters.each((i, element) => {
    const itemName = $(element).attr('data-pop');
    let itemCheckValues = [];
    const isChecked = $(`.scroll-menu [data-btn=${itemName}]`).hasClass('checked');
    if ((itemName === 'exhaust' || itemName === 'price') && isChecked) {
      itemCheckValues = [$(element).find('.noUi-handle-lower').attr('aria-valuenow'), $(element).find('.noUi-handle-upper').attr('aria-valuenow')];
    } else if (isChecked) {
      itemCheckValues = $(element).find('input:checked').map((_idx, elem) => $(elem).val()).get();
    }
    filterWheres.push({ key: itemName, value: itemCheckValues });
  });

  list.each((i, e) => {
    let isShow = true;
    // 依據目前條件篩選 (各分類 and )
    filterWheres.forEach((obj) => {
      if (obj.value != null && obj.value.length !== 0) {
        if (!filterType(e, obj.key, obj.value)) {
          isShow = false;
        }
      }
    });
    if (isShow) {
      $(e).show();
    }
  });
  filterResult(list);

  $('.pop-up a.remove').not('.point').addClass('disabled');
}

// 排序二擇一
function checkSotType() {
  const sotPRIA = $('[data-pop="sot"] input[id="sot-PRIA"]');
  const sotPRIB = $('[data-pop="sot"] input[id="sot-PRIB"]');

  sotPRIB.on('click', () => {
    sotPRIA.prop('checked', false);
  });
  sotPRIA.on('click', () => {
    sotPRIB.prop('checked', false);
  });
}

// navbar-my-list-tracking ,navbar-my-list-history 觀看紀錄內，若無內容高度與有內容時相同切換空白
function turnSwiperButton(swiperID) {
  const hasCar = $(`#${swiperID} .swiper-wrapper .swiper-slide`).filter(function checkNone() {
    return $(this).css('display') !== 'none';
  }).length > 0;
  const $swiper = $(`#${swiperID} .container-huge`);
  const $swiperEmpty = $(`#${swiperID} .default`);
  if (hasCar) {
    $swiper.show();
    $swiperEmpty.hide();
  } else {
    $swiper.hide();
    $swiperEmpty.show();
  }
}

// 初始化車款事件
export function initQueryFilter() {
  // 儲存選項
  $('.global-menu-desktop .pop-up a.save').on('click', function save() {
    const item = $(this).closest('.pop-up');
    const popup = item.attr('data-pop');
    // checkbox radio
    const itemCheckValues = item.find('input:checked').map((_idx, elem) => $(elem).val()).get();
    querFilter(popup, itemCheckValues);
  });
  checkSotType();

  // 追蹤、紀錄初始化
  turnSwiperButton('navbar-my-list-tracking');
  turnSwiperButton('navbar-my-list-history');
}


// 顯示追蹤清單
function switchTrackingCar(sno, isShow, swiperCollect) {
  const $current = $(`#navbar-my-list-tracking .product-swiper-wrap .icon-collect-btn[data-sno="${sno}"]`);

  if (isShow) {
    $current.closest('.swiper-slide').show();
  } else {
    $current.closest('.swiper-slide').hide();
  }
  // navbar-my-list-tracking
  swiperCollect[0].update();
  turnSwiperButton('navbar-my-list-tracking');
}

// 標記追蹤
function tagTrackingCar(sno, isTag) {
  const trackingText = 'active';
  const $current = $(`.icon-collect-btn[data-sno="${sno}"]`);
  if (isTag) {
    $current.addClass(trackingText);
  } else {
    $current.removeClass(trackingText);
  }
}
// 追蹤服務
function setTracking(sno, isTrack) {
  $.ajax({
    url: '/api/owner_tracking.ashx',
    dataType: 'json',
    type: 'POST',
    data: { sno, isTrack },
    success: function result(response) {
      if (response.RS !== 'OK') {
        console.log(`owner_tracking error: ${response.RS}`);
      }
    },
  });
}
// 加入追蹤按鈕
export function collectBtn(swiperCollect) {
  $('.icon-collect-btn').on('click', function a(e) {
    const name = $(this).closest('.swiper-slide').find('h4').text();
    if ($(this).hasClass('active')) {
      $('#removeCollect .popup-text').text(`確定要將「${name}」自追蹤清單中移除？`);
      $('#popup-removeCollect').trigger('click');
      $('.btn.remove').on('click', () => {
        $(e.target).removeClass('active');
        setTracking($(this).data('sno'), '0');
        switchTrackingCar($(this).data('sno'), false, swiperCollect);
        tagTrackingCar($(this).data('sno'), false);
      });
    } else {
      $('#addCollect .popup-text').text(`已成功將「${name}」加入追蹤清單！`);
      $('#popup-addCollect').trigger('click');
      $(this).addClass('active');
      setTracking($(this).data('sno'), '1');
      switchTrackingCar($(this).data('sno'), true, swiperCollect);
      tagTrackingCar($(this).data('sno'), true);
    }
  });
}

// footer 追蹤清單 瀏覽記錄
export function trackingBtn(callback) {
  const tracking = $('#navbar-my-list-tracking-tab');
  const history = $('#navbar-my-list-history-tab');
  $('.trackingActive').on('click', function click() {
    callback('tracking');
    if ($(this).text() === '追蹤清單') {
      tracking.addClass('active');
      tracking.attr('aria-selected', true);
      history.removeClass('active');
      history.attr('aria-selected', false);
    } else {
      tracking.removeClass('active');
      tracking.attr('aria-selected', false);
      history.addClass('active');
      history.attr('aria-selected', true);
    }
  });
}

// 同意會員權益
function agreeBenefitsAndPrivacy(callback) {
  $.ajax({
    url: `/api/owner_agree_privacy.ashx?rnd=${Math.random()}`,
    dataType: 'json',
    type: 'POST',
    success(response) {
      if (response.RS !== 1) {
        console.log(response.Msg);
        return;
      }
      callback();
    },
    error() {
      // alert("ERROR");
    },
    complete() {
      // Handle the complete event
    },
  });
}

export function initBenefitsAndPrivacy() {
  let isAgree = false;
  $('#linkPrivacy').fancybox({
    clickSlide: false,
    afterClose: () => {
      if (!isAgree) {
        window.location = 'logout.ashx';
      }
    },
  });

  $('#privacySubmit').on('click', () => {
    isAgree = true;
    setTimeout(() => {
      agreeBenefitsAndPrivacy(() => {
        // 非同步處理頁面即可
      });
    }, 100);
    $.fancybox.close();
  });

  $('#linkPrivacy').on('click', () => {
    setTimeout(() => {
      const outerH = Math.round($('#benefitsAndPrivacyVersion .popup-scroll').height());
      const innerH = $('#benefitsAndPrivacyVersion .popup-scroll').prop('scrollHeight');
      console.log(outerH, innerH);
      $('#benefitsAndPrivacyVersion .popup-scroll').on('scroll', function popupScroll() {
        const scrollTop = $(this).scrollTop();


        const scrollToBottom = scrollTop - (innerH - outerH);
        // console.log(scrollToBottom);
        // 滑動無法精準到等於0
        if (scrollToBottom > -10) {
          $('#benefitsAndPrivacyVersion [data-scrollTip]').addClass('hide');
          $('#benefitsAndPrivacyVersion [data-agree]').prop('disabled', false);
        }
      });
    }, 250);
  });
  const $isCheckBenefits = $('#isCheckBenefits');
  if ($isCheckBenefits.length > 0 && $isCheckBenefits.val().toLowerCase() === 'true') {
    $('#linkPrivacy').trigger('click');
  }
}

export default {
  initQueryFilter,
  collectBtn,
  trackingBtn,
  querFilter,
  initBenefitsAndPrivacy,
};
