import $ from 'jquery';

// footer 智能客服 常見問題
export function helpBtn() {
  const serviceTab = $('#navbar-smart-custom-tab');
  const faqTab = $('#navbar-FAQ-tab');
  const serviceContant = $('#navbar-smart-custom-tracking');
  const faqContant = $('#navbar-FAQ');

  function showFaq(iShow) {
    if (iShow) {
      faqTab.addClass('active');
      faqTab.attr('aria-selected', true);
      faqContant.addClass('active');
      faqContant.addClass('show');
      faqContant
        .find('.product-swiper-wrap')
        .empty()
        .append(
          `<iframe id='faq_iframe' style='width: 100%; height: 100%;' src='${faqContant.data(
            'src'
          )}'></iframe>`
        );
    } else {
      faqTab.removeClass('active');
      faqTab.attr('aria-selected', false);
      faqContant.removeClass('active');
      faqContant.removeClass('show');
      faqContant.find('.product-swiper-wrap').empty();
    }
  }

  function showService(iShow) {
    if (iShow) {
      $.ajax({
        url: document.location.origin+'/api/chatbot.ashx',
        type: 'POST',
        dataType: 'json',
        data: {},
        success: (result) => {
          if (result && result.url) {
            serviceTab.addClass('active');
            serviceTab.attr('aria-selected', true);
            serviceContant.addClass('active');
            serviceContant.addClass('show');
            serviceContant.find('.product-swiper-wrap').empty().append(`<iframe id='srm_iframe' allow='microphone' style='width: 100%; height: 100%;' src='${result.url}'></iframe>`);
          }
        },
      }).fail((e) => {
        console.log('error', e);
      });
    } else {
      serviceTab.removeClass('active');
      serviceTab.attr('aria-selected', false);
      serviceContant.removeClass('active');
      serviceContant.removeClass('show');
      serviceContant.find('.product-swiper-wrap').empty();
    }
  }
  
  $(document).on('click', '.helpActive', function() {
    if ($(this).text() === '智能客服') {
      showService(true);
      showFaq(false);
    } else {
      showService(false);
      showFaq(true);
    }
    $('#help-trigger')[0].click();
  });

  serviceTab.on('click', () => {
    console.log('serviceTab click');
    showService(true);
    showFaq(false);
  });

  faqTab.on('click', () => {
    console.log('faqTab click');
    showService(false);
    showFaq(true);
  });

  $('.icon-help')
    .parent()
    .click(() => {
      showService(true);
      showFaq(false);
      $('#help-trigger')[0].click();
    });

  $('#global-menu-help-center .close-btn').on('click', () => {
    showFaq(false);
    showService(false);
  });

  const $isAutoOpenChartbot = $('#isAutoOpenChartbot');
  if (
    $isAutoOpenChartbot.length > 0 &&
    $isAutoOpenChartbot.val().toLowerCase() === 'true'
  ) {
    $('.icon-help')
      .parent()
      .trigger('click');
  }
}

export default {
  helpBtn,
};
