import '@/vendors/anteater.validate.min';

const { $ } = window;

/**
 * @usage tmpl(
 *      'hello {{ name }}!',
 *      { name: 'ash' }
 * );
 * => hello ash!
 *
 * @param {any} str
 * @param {any} data
 * @returns
 */
export function tmpl(pStr, pData) {
  let reg;
  let str = pStr;
  Object.keys(pData).forEach((item) => {
    reg = new RegExp(`{{ ${item} }}`, 'g');
    str = str.replace(reg, pData[item]);
  });
  return str;
}
export function getQueryString(pKey) {
  const a = window.location.search.substr(1).split('&');
  const b = {};
  for (let i = 0; i < a.length; i += 1) {
    const p = a[i].split('=');
    if (p.length === 2) {
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
    }
  }
  return b[pKey];
}

function initSetVCode($img) {
  $img.attr('src', `api/vcode.ashx?rnd=${Math.round(Math.random() * 999999)}`);
}

export function setVCode($img) {
  initSetVCode($img);
  $img.on('click', function click() {
    initSetVCode($(this));
  });
}

export function padLeft(str, lenght) {
  if (str.toString().length >= lenght) {
    return str;
  }
  return padLeft(`0${str}`, lenght);
}

// loading樣式
export function loadingView() {
  return '<div class="fetch-loading">'
                  + '<div class="spinner" role="status"><i class="icon icon-reload"></i> <span'
                          + ' class="sr-only">Loading...</span></div>'
              + '</div>';
}

export function setGA(id) {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      event: 'userID',
      event_userID: id,
    });
  }
}

// eslint-disable-next-line no-extend-native
export function stringFormat(args) {
  let result = this;
  if (arguments.length > 0) {
    if (arguments.length === 1 && typeof args === 'object') {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in args) {
        if (args[key] !== undefined) {
          const reg = new RegExp(`({${key}})`, 'g');
          result = result.replace(reg, args[key]);
        }
      }
    } else {
      for (let i = 0; i < arguments.length; i += 1) {
        // eslint-disable-next-line prefer-rest-params
        const argument = arguments[i];
        if (argument !== undefined) {
          const reg = new RegExp(`({[${i}]})`, 'g');
          result = result.replace(reg, argument);
        }
      }
    }
  }
  return result;
}

export function getURLParameter(sParam) {
  const getParaFromUrl = $.address.parameter(sParam);
  if (getParaFromUrl !== '' && getParaFromUrl !== undefined) {
    return decodeURI(getParaFromUrl);
  }
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i += 1) {
    const sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return decodeURI(sParameterName[1]);
    }
  }
  return '';
}
/**
 *
 * @param {*} str value
 * @param {*} type 'num' 'engnum' 'mail' 'mobile' 'htmltag'
 * @returns
 */
export function verityInput(str, type) {
  const regNum = /^[0-9]+$/;
  const regEngNum = /^[A-Za-z0-9]+$/;
  const regex = /["><`＂‵＜＞]$/g;
  switch (type) {
    case 'num':
      return regNum.test(str);
    case 'engnum':
      return regEngNum.test(str);
    case 'mail':
      return window.anteater.Validate.isEmail(str);
    case 'mobile':
      return window.anteater.Validate.isMobileNumber(str);
    case 'htmltag':
      return !regex.test(str);
    default:
  }
  return false;
}

/**
 * verityInput regex
 * @returns
 */
export function getSpecialTip() {
  const reg = '"><`＂‵＜＞';
  return `請勿輸入特殊符號！${reg}`;
}

export default {
  tmpl,
  getQueryString,
  setVCode,
  padLeft,
  loadingView,
  setGA,
  stringFormat,
  getURLParameter,
  verityInput,
  getSpecialTip,
};
