import $ from 'jquery';
import 'bootstrap';
import AOS from '@/components/aos';
import '@fancyapps/fancybox';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-TW.min';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'nouislider/distribute/nouislider.css';
import 'select2/dist/css/select2.min.css';
import 'scrolltofixed';
import 'select2';
import { setGA, verityInput, getSpecialTip } from '@/utils/util-extent';
import objectFitImages from 'object-fit-images';
import globalMenu from './components/global-menu';
import globalHelp from './components/global-help';
import { hasTouch } from './utils/device';

import './components/input-select';
import '@/vendors/jquery.address.min';
import './components/theModel-extent';


import '~/sass/global.scss';
import '~/sass/icons/toyota-2016.scss';
import '~/sass/icons/toyota-2020.scss';

// const swiperHistory = null;
let initScrollTop = $(document).scrollTop();
let initWindowWidth = $(window).outerWidth();
const initWindowHeight = $(window).innerHeight();
const navHeight = 0;
console.log(initWindowWidth);
$.fancybox.defaults.autoFocus = false;
$.fancybox.defaults.touch = false;

const smartMenu = () => {
  const initHasTouch = hasTouch();
  initWindowWidth = $(window).outerWidth();
  if (initHasTouch !== true && initWindowWidth >= 1024) {
    // pc
    $('.fixed-menu').addClass('mouseover-style');
    $('.fixed-menu').hover(function a() {
      $(this).toggleClass('active');
    });
  } else if (initWindowWidth >= 1024) {
    // ipad
    $('.fixed-menu').addClass('show');
    $('.fixed-menu .col-auto').removeClass('col-lg-12');
    $('.global-footer').css('padding-bottom', '86px');
  }
  // mobile ipad
  // $(window).scroll(() => {
  //   const scrolled = $(document).scrollTop();
  //   if (scrolled > navHeight) {
  //     $('.fixed-menu').addClass('hide');
  //   } else {
  //     $('.fixed-menu').removeClass('hide');
  //   }
  //   if (scrolled > initScrollTop) {
  //     $('.fixed-menu').removeClass('sticky');
  //   } else {
  //     $('.fixed-menu').addClass('sticky');
  //   }

  //   if (scrolled > navHeight) {
  //     if ($('.fixed-menu').hasClass('hide')) {
  //       const windowH = $('body,html').offset().top
  //         + $('body,html').outerHeight()
  //         - window.innerHeight
  //         - 100;
  //       // console.log(scrolled,windowH)
  //       if (scrolled >= windowH) {
  //         $('.fixed-menu').removeClass('hide');
  //       } else {
  //         $('.fixed-menu').addClass('hide');
  //       }
  //     }
  //   }
  //   initScrollTop = $(document).scrollTop();
  // });
  // }

  $('.go-to-top').on('click', () => {
    $('html,body').stop().animate({
      scrollTop: 0,
    }, 800);
  });
  $('#gotop-mobile').on('click', () => {
    $('html,body').stop().animate({
      scrollTop: 0,
    }, 800);
  });
};

const carShowcase = (group, el) => {
  $(group).find(el).hover(function off() {
    $(group).find(el).not(this).toggleClass('off');
  });
};
// const loading = () => {
//   setTimeout(() => {
//     $('.loading').fadeOut(800);
//   }, 2000);
// };
const heightVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const $someImages = $('.list-img img, article.car-show-list img, article.history-cars-info img, article.img-subtext-swiper img, .kv-image');

function initUserID() {
  const num = $('#seqIndex').val();
  if (num !== '') {
    setGA(num);
  }
}

function appAdBar() {
  // QRcode 顯示
  $('.app-download').on('click', () => {
    const wWidth = $(window).outerWidth();
    console.log(wWidth);
    if (wWidth < 1024) { // mobile直接開啟
      if (/Android/i.test(navigator.userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.hotaimotor.toyotasmartgo');
      } else {
        window.open('https://itunes.apple.com/app/id1609000399');
      }
      $.ajax({
        url: `/api/app_click.ashx?rnd=${Math.random()}&type=download`,
        dataType: 'json',
        type: 'POST',
        async: true,
        success(response) {
          if (response.RS !== 1) {
            console.log(`app_click : ${response.Msg}`);
          }
        },
        error() {
          // alert("ERROR");
        },
        complete() {
          // Handle the complete event
        },
      });
    } else { // pc顯示QRcode
      $('.pc-qr-code').addClass('active');
      $('.pc-qr-code , .qrcode-image').show();
    }
  });
  $('.pc-qr-code .close-button').on('click', () => {
    $('.pc-qr-code').removeClass('active');
    $('.pc-qr-code').hide();
  });

  // 關閉廣告
  $('#index-ad .app-logo .close-button').on('click', () => {
    $('#index-ad').hide(500);
    $.ajax({
      url: `/api/app_click.ashx?rnd=${Math.random()}&type=close`,
      dataType: 'json',
      type: 'POST',
      async: true,
      success(response) {
        if (response.RS !== 1) {
          console.log(`app_click : ${response.Msg}`);
        }
      },
      error() {
        // alert("ERROR");
      },
      complete() {
        // Handle the complete event
      },
    });
  });
  $('.pc-qr-code').hide();
}

function searchEvents() {
  $('body').on('submit', '#global-menu-search form, #global-menu-mobile form', () => {
    const $search = $('#global-menu-search form').find('input[name=search]');
    const $searchMobile = $('#global-menu-mobile form').find('input[name=search]');
    if (verityInput($search.val(), 'htmltag') === false
        || verityInput($searchMobile.val(), 'htmltag') === false) {
      // eslint-disable-next-line no-alert
      alert(getSpecialTip());
      return false;
    }
    return true;
  });
}
const iconListScroll = () => {
  // icon list fix scroll
  document.querySelectorAll('.scroll-icon-menu ul').forEach((element) => {
    const oWidth = element.offsetWidth;
    const sWidth = element.scrollWidth;
    const sLeft = element.scrollLeft;
    if (oWidth - sWidth !== 0) {
      console.log(sLeft);
      $(element).next().addClass('show');
    }
  });
  $('.scroll-icon-menu ul').on('scroll', function ulScroll() {
    const scrollLeftMax = this.scrollWidth - this.clientWidth;
    const scrollLeft = Math.ceil(this.scrollLeft);
    // console.log(scrollLeft);
    // console.log(scrollLeftMax);
    if (scrollLeft > 0) {
      $(this).prev().addClass('show');
      $(this).next().addClass('show');
    }
    if (scrollLeftMax - scrollLeft <= 1) {
      $(this).next().removeClass('show');
    }
    if (scrollLeft === 0) {
      $(this).prev().removeClass('show');
    }
  });
  // click arrow
  $('.scroll-icon-menu .arrow-next').click(function next() {
    const scrollWidth = $(this).prev().width();
    const scrollLeft = $(this).prev().scrollLeft();
    // console.log(scrollWidth);
    $(this).prev().animate({
      scrollLeft: scrollLeft + (scrollWidth / 2),
    });
  });
  $('.scroll-icon-menu .arrow-prev').click(function prev() {
    const scrollWidth = $(this).next().width();
    const scrollLeft = $(this).next().scrollLeft();
    // console.log(scrollWidth);
    $(this).next().animate({
      scrollLeft: scrollLeft - (scrollWidth / 2),
    });
  });

  $('.scroll-icon-menu .icon-list li').click(function scroll() {
    let pos = $(this).position().left;
    const posWidth = $(this).width();
    const currentScroll = $(this).parent().scrollLeft();
    const divWidth = $(this).parent().width();
    pos = (pos + currentScroll) - (divWidth / 2) + (posWidth / 2) - 30;
    $(this).parent().animate({
      scrollLeft: pos,
    });
  });
};


$(document).ready(() => {
  console.log('page init');
  heightVH();
  // loading();
  $('.scroll-menu:not(.tab-group) .type-text').click(function active() {
    $(this).addClass('active');
    $('.scroll-menu .type-text').not(this).removeClass('active');
  });

  $('.scroll-menu-list li').click(function scroll() {
    let pos = $(this).position().left;
    const posWidth = $(this).width();
    const currentscroll = $(this).parent().scrollLeft();
    const divwidth = $(this).parent().width();
    pos = (pos + currentscroll) - (divwidth / 2) + (posWidth / 2) + 10;

    $(this).parent().animate({
      scrollLeft: pos,
    });
  });

  initUserID();

  globalMenu();
  globalHelp();
  smartMenu();
  carShowcase('.swiper-collect', '.car-show-list');
  carShowcase('.swiper-show', '.car-show-list');
  carShowcase('.swiper-history', '.history-cars-info');
  objectFitImages($someImages);
  appAdBar();
  iconListScroll();

  AOS.init({
    once: true,
  });
  searchEvents();
});

$(window).scroll(() => {
  const scrolled = $(document).scrollTop();
  if (initWindowWidth < 1024) {
    if (scrolled > navHeight) {
      $('.fixed-menu').addClass('hide');
      $('.fixed-menu-mobile').removeClass('quick-show');
    } else {
      $('.fixed-menu').removeClass('hide');
      $('.fixed-menu-mobile').addClass('quick-show');
    }
    if (scrolled > initScrollTop) {
      $('.fixed-menu').removeClass('sticky');
      $('.fixed-menu-mobile').removeClass('quick-show');
    } else {
      $('.fixed-menu').addClass('sticky');
      $('.fixed-menu-mobile').addClass('quick-show');
    }

    if (scrolled > navHeight) {
      if ($('.fixed-menu').hasClass('hide')) {
        const windowH = $('body,html').offset().top
            + $('body,html').outerHeight()
            - window.innerHeight
            - 100;
          // console.log(scrolled,windowH)
        if (scrolled >= windowH) {
          $('.fixed-menu').removeClass('hide');
          $('.fixed-menu-mobile').addClass('quick-show');
        } else {
          $('.fixed-menu').addClass('hide');
          // $('.fixed-menu-mobile').addClass('quick-show');
        }
      }
    }
    initScrollTop = $(document).scrollTop();
    // $('#gotop-mobile').show();
  }
  if (scrolled > initWindowHeight - 72) {
    $('#gotop-mobile').fadeIn(300);
    $('.fixed-menu .go-to-top').parent().addClass('d-lg-block');
  } else {
    $('#gotop-mobile').fadeOut(200);
    $('.fixed-menu .go-to-top').parent().removeClass('d-lg-block');
  }
});

window.addEventListener('resize', () => {
  heightVH();
  smartMenu();
});
